(function ($, _) {
    var MiddlewareService = function(){
        var stack = [],
        isProcessingStack = false,
        commonState = { task: 0 };


        function push(fn){
            stack.push(fn);

            if (!isProcessingStack) {
                next();
            }
        }

        function next(err){
            if(err)
                throw new Error(err);

            var fn = stack.shift();

            if(fn){
                isProcessingStack = true;
                fn.start(commonState, next);
            }
            else
                isProcessingStack = false;
        }

        return {
            push: push
        };
    };

    var Request = function (fn, options) {
        this.result = $.Deferred();
        this.options = options;
        this.fn = fn;
    }

    Request.prototype.start = function (state, next) {
        var def = this.fn(this.options);
        var that = this, result = this.result;

        def.done(result.resolve);

        def.fail(result.reject);

        def.always(function () {
            next();
        });
    };

    Request.prototype.hash = function () {

        if (this.options.hash) {
            return this.options.hash;
        }

        var method = this.options.type || 'get';
        var url = this.options.url;

        return method + '-' + url;
    };

    var queues = {
        _q: {},

        add: function (req) {
            var hash = req.hash();

            if (!(hash in this._q)) {
                this._q[hash] = MiddlewareService();
            }

            this._q[hash].push(req);
        }
    }

    var addRequest = function (ajaxFn, options) {

        var req = new Request(ajaxFn, options);

        queues.add(req);

        return req.result;
    }

    $.ajax = _.wrap($.ajax, function (ajax, url, options) {

        var u, method;

        if (_.isPlainObject(options) && options.doNotQueue) {
            return ajax(url);
        }

        if (typeof url === 'string') {
            u = url;
        } else {
            u = url.url;
            options = url;
        }

        return addRequest(ajax, options);
    });

})(jQuery, _);
